@import url("https://fonts.googleapis.com/css2?family=Muli:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Chivo:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;700&display=swap");

:root {
  --color-primero: #0027ff;
  --color-segundo: #5800ff;
  --color-tercero: #d800ff;
  --color-texto: #444;
}

/* SELECTORES GENERALES */
* {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: none;
  color: var(--color-primero) !important;
}

ul,
ol {
  padding-left: 1rem;
}


/* Navbar */

.menu-colapsado {
  margin: 10px 0;
  text-align: center;
  font-size: 18px;
  padding: 0;
}

.enlace {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin: 0 8px;
  position: relative;
}

.enlace::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  content: "";
  border-bottom: 4px transparent solid;
}

.enlace:hover::before {
  width: 100%;
  border-bottom: 4px var(--color-primero) solid;
  transition: width 0.4s ease-out;
}

.enlace.attention {
  border-radius: 10px;
  color: #fff !important;
  background-color: var(--color-segundo);
  padding: 10px 20px !important;
  transition: background-color 0.4s ease-out;
}

.enlace.attention:hover {
  background-color: #222;
  transition: background-color 0.4s ease-out;
}

.enlace.attention::before {
  display: none;
}

.navbar-img {
  height: 80px;
}

@media (max-width: 1199px) {
  .menu-colapsado {
    font-size: 14px;
  }
}

/* Crece el tamaño de fuente porque el menu se colapsa a los <=992px */
@media (max-width: 991px) {
  .menu-colapsado {
    font-size: 20px;
  }
}

.mi-navbar {

  width: 100vw;
}

.mi-navbar-space {
  height: 176px;
}

.mi-navbar-container {
  padding: 10px 0px;

}

@media (max-width: 575px) {
  .mi-navbar-container {
    display: flex;
    flex-direction: column;
    align-items: center !important;
    justify-content: center !important;
  }

  .navbar-img {
    height: auto;
    width: 87vw;
    margin: 10px 0;
  }
}

/* Títulos */
/* h1 Para resoluciones sm */
@media (max-width: 575px) {
  h1 {
    font-size: 2.5rem !important;
  }
}

/* landing */
.landing-texto-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 85vh;
  max-height: 85vh;
  /* El efecto typewrite altera la altura */
  padding: 0 150px;
  opacity: 0;
  animation: aparecer 2s ease-in forwards;
}

@keyframes aparecer {
  to {
    opacity: 1;
  }
}

.navbar-toggler {
  border: 2px rgba(0, 0, 0, 0.3) solid;
  padding: 0.2rem 0.4rem;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
  background-color: rgba(0, 39, 255, 0.2);
}

.landing-texto-container h1 {
  font-family: "Poppins", sans-serif;
  font-size: 7rem;
  font-weight: 700;
  line-height: 6.4rem;
}

.landing-texto-container .texto-fijo {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 40%;
}

.landing-texto-container .texto-fijo h1 {
  color: #333;
}

.landing-texto-container .texto-movil {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 60%;
}

.landing-texto-container .texto-movil h1 {
  color: var(--color-segundo);
}

/* Se rompía más allá de 1400px */
@media (max-width: 1599px) {
  .landing-texto-container {
    padding: 0 100px;
  }

  .landing-texto-container h1 {
    font-size: 6rem;
    line-height: 5.5rem;
  }
}

@media (max-width: 1399px) {
  .landing-texto-container {
    padding: 0 70px;
    height: 80vh;
    max-height: 80vh;
  }

  .landing-texto-container h1 {
    font-size: 6rem;
    line-height: 5.5rem;
  }
}

@media (max-width: 1199px) {
  .landing-texto-container {
    padding: 0 70px;
    height: 75vh;
    max-height: 75vh;
  }

  .landing-texto-container h1 {
    font-size: 5.5rem;
    line-height: 5rem;
  }
}

@media (max-width: 991px) {
  .landing-texto-container {
    padding: 0 35px;
    margin-top: 20px;
  }

  .landing-texto-container h1 {
    font-size: 4.9rem;
    line-height: 4.7rem;
  }
}

@media (max-width: 767px) {
  .landing-texto-container {
    padding: 0 25px;
  }

  .landing-texto-container h1 {
    font-size: 4.4rem;
    line-height: 4.7rem;
  }
}

@media (max-width: 575px) {
  .landing-texto-container {
    padding: 0 15px;
    height: 67vh;
    max-height: 67vh;
  }

  .landing-texto-container h1 {
    text-align: center;
    font-size: 2.7rem;
    line-height: 2.8rem;
  }
}

/* Barra Quieros */
section.quieros {
  margin: 30px 0 0;
}

section.quieros h1 {
  margin: 60px 0;
  width: 100%;
  font-size: 60px;
  text-align: center;
}

section.quieros h1 i {
  color: var(--color-segundo);
}

@media (max-width: 576px) {
  section.quieros h1 {
    margin: 0;
    font-size: 35px;
    font-weight: 700;
  }

  section.quieros h1 i {
    margin-top: 15px;
    display: block;
  }
}

section.quieros .barra-quiero {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 150px;
  margin-top: 30px;
  width: 100%;
}

section .barra-quiero h2 {
  font-size: 1.5rem;
  line-height: 1.75rem;
  text-transform: uppercase;
  display: inline;
  color: #fff;
}

section .barra-quiero h3 {
  font-weight: 300;
  font-size: 1rem;
  line-height: 0.75rem;
  text-transform: none;
  color: #fff;
}

section .barra-quiero p {
  font-size: 1rem;
  line-height: 1.4rem;
  margin: 0.8rem 0;
  color: #fff;
  opacity: 0;
}

section .barra-quiero .bloque-quiero {
  position: absolute;
  width: 25%;
  left: 0;
  bottom: 0;
  padding: 20px 30px;
  max-height: 9.5em;
  background: #222;
  z-index: 99;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}

section .barra-quiero .bloque-quiero:hover {
  background-color: var(--color-segundo);
  max-height: 500px;
  transition: background-color ease 0.3s, max-height ease-in 0.5s;
}

section .barra-quiero .bloque-quiero:hover p {
  opacity: 1;
  transition: opacity ease-in 0.4s;
}

section .barra-quiero .bloque-quiero:nth-child(1) {
  margin-left: 0;
}

section .barra-quiero .bloque-quiero:nth-child(2) {
  margin-left: 25%;
}

section .barra-quiero .bloque-quiero:nth-child(3) {
  margin-left: 50%;
}

section .barra-quiero .bloque-quiero:nth-child(4) {
  margin-left: 75%;
}

section .barra-quiero .bloque-quiero i {
  color: #fff;
  font-size: 1.5rem;
  display: inline-block;
  text-align: right;
  transform: rotate(0deg);
  transition: transform ease-in 0.3s;
}

section .barra-quiero .bloque-quiero:hover i {
  transform: rotate(90deg);
  transition: transform ease-in 0.3s;
}

@media (max-width: 1199px) {
  section.quieros .barra-quiero {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    height: 480px;
  }

  section.quieros .barra-quiero p {
    opacity: 1;
  }

  section.quieros .barra-quiero .bloque-quiero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 50%;
    height: 240px;
    max-height: 370px;
  }

  section .barra-quiero .bloque-quiero:hover {
    max-height: 370px;
  }

  section.quieros .barra-quiero .bloque-quiero:nth-child(1) {
    margin-left: 0;
  }

  section.quieros .barra-quiero .bloque-quiero:nth-child(2) {
    margin-left: 0;
  }

  section.quieros .barra-quiero .bloque-quiero:nth-child(3) {
    margin-left: 0;
  }

  section.quieros .barra-quiero .bloque-quiero:nth-child(4) {
    margin-left: 0;
  }

  section .barra-quiero .bloque-quiero i {
    display: none;
  }
}

@media (max-width: 991px) {
  section.quieros .barra-quiero {
    height: 540px;
  }

  section.quieros .barra-quiero p {
    opacity: 1;
  }

  section.quieros .barra-quiero .bloque-quiero {
    height: 270px;
    max-height: 370px;
  }

  section .barra-quiero .bloque-quiero:hover {
    max-height: 370px;
  }
}

@media (max-width: 767px) {
  section.quieros .barra-quiero {
    height: 640px;
  }

  section.quieros .barra-quiero .bloque-quiero {
    height: 320px;
    max-height: 370px;
  }

  section .barra-quiero .bloque-quiero:hover {
    max-height: 370px;
  }
}

@media (max-width: 575px) {
  section.quieros .barra-quiero {
    display: flex;
    flex-direction: column;
    height: auto;
  }

  section.quieros .barra-quiero .bloque-quiero {
    display: inline;
    width: 100%;
    height: 270px;
    max-height: 270px;
  }
}

/* Información Intro */
.info-intro-background {
  /* background-image: url(https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80); */
  background-color: #ddd;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 40px 70px 0;
}

.info-intro-background h1 {
  width: 100%;
  font-size: 70px;
  text-align: center;
  margin-bottom: 45px;
}

.info-intro-background h1 i {
  color: var(--color-segundo);
}

.info-intro-background {
  letter-spacing: 0px;
}

.info-intro-background .resaltar {
  display: inline;
  margin: 0 15px;
  font-weight: 500;
  color: var(--color-primero);
}

.info-intro-background .resaltar:first-of-type {
  margin-left: 0;
}

.info-intro-background ul {
  display: block;
  margin-left: 10px;
}

.info-intro-background ul li {
  display: block;
  position: relative;
}

.info-intro-background ul li:not(:last-child) {
  margin-bottom: 16px;
}

.info-intro-background ul li:before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -25px;
  background: linear-gradient(45deg,
      var(--color-segundo),
      var(--color-tercero));
  height: 12px;
  width: 12px;
  border-radius: 50%;
}

.info-intro-background ol {
  counter-reset: item;
  list-style-type: none;
  line-height: 2.2;
  margin-left: -40px;
}

.info-intro-background ol li {
  display: block;
  position: relative;
  margin-left: 22px;
}

.info-intro-background ol li span {
  margin-left: 35px;
}

.info-intro-background ol li:before {
  display: flex;
  align-items: center;
  justify-content: center;
  content: counter(item) " ";
  counter-increment: item;
  color: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: linear-gradient(45deg,
      var(--color-segundo),
      var(--color-tercero));
  height: 1.5em;
  width: 1.5em;
  border-radius: 2em;
}

@media (max-width: 1399px) {
  .info-intro-background ol li:before {
    display: none;
  }

  .info-intro-background ol {
    list-style-type: counter;
    margin-left: 10px;
  }

  .info-intro-background ol li {
    display: list-item;
    margin: 0;
  }

  .info-intro-background ol li span {
    margin-left: 5px;
  }
}

@media (max-width: 1199px) {
  .info-intro-background {
    padding: 40px 50px 0;
  }
}

@media (max-width: 767px) {
  .info-intro-background {
    padding: 40px 35px 0;
  }

  .info-intro-background ol li {
    margin-left: 0px;
  }
}

@media (max-width: 575px) {
  .info-intro-background {
    padding-top: 35px;
  }

  .info-intro-background h1 {
    margin: 0;
    margin-bottom: 35px;
    font-size: 55px;
    line-height: 55px;
    font-weight: 700;
  }
}

@media (max-width: 360px) {
  .info-intro-background {
    padding: 35px 10px 0;
  }
}

/*  FOOTER */
footer {
  border: none;
  background-color: rgba(0, 0, 0, 0.08);
}

footer .container {
  padding: 50px 0;
}

footer ul {
  list-style-type: none;
  padding: 0;
}

footer li {
  margin-bottom: 0.4rem;
  cursor: pointer;
}

footer li:hover {
  color: #222;
}

footer li:last-child {
  margin-bottom: 0;
}

@media (max-width: 575px) {

  footer p,
  footer h4,
  footer ul {
    padding: 0 1rem;
  }
}

/* Para activar el gif al hover en el portafolio */
.gif-container {
  position: relative;
}

.gif-container-before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.gif-container:hover .gif-container-before {
  opacity: 1;
}

.tecnologias-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.tecnologia {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 30%;
  margin-bottom: 20px;
}

.tecnologia img {
  width: 100px;
  height: 100px;
  margin-bottom: 5px;
  filter: grayscale(100%);
  transition: filter 0.4s ease, transform 0.4s ease-in-out;
}

.tecnologia h4 {
  text-align: center;
  margin: 0;
  transition: color 0.4s ease;
}

.tecnologia h4::before {
  content: "";
  width: 100px;
  height: 15px;
  border-radius: 50%;
  filter: blur(3px);
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
  top: 90px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.4s ease;
}

.tecnologia:hover h4::before {
  opacity: 1;
  transition: opacity 0.4s ease;
}

.tecnologia:hover img {
  transform: translateY(-10%);
  filter: grayscale(0);
  transition: filter 0.4s ease, transform 0.4s ease-in-out;
}

.tecnologia:hover h4 {
  color: var(--color-segundo);
  transition: color 0.4s ease;
}

@media (max-width: 1199px) {
  .tecnologia {
    margin-right: 5px;
    margin-bottom: 25px;
  }

  .tecnologia img {
    width: 80px;
    height: 80px;
  }

  .tecnologia h4 {
    font-size: 1rem;
  }
}

@media (max-width: 992px) {
  .tecnologia img {
    filter: grayscale(0);
  }

  .tecnologia h4::before {
    display: none;
  }
}

/* La sección se rompía a esta resolución, aunque no fuera estándar */
@media (max-width: 450px) {
  .tecnologia {
    margin-right: 5px;
    margin-bottom: 25px;
  }

  .tecnologia img {
    width: 50px;
    height: 50px;
  }

  .tecnologia h4 {
    font-weight: 300;
    letter-spacing: 0px;
    font-size: 0.75rem;
  }

  .tecnologia h4::before {
    display: none;
  }
}

.boton-movil {
  cursor: pointer;
  margin-left: -5px;
  padding: 5px;
  border-radius: 5px;

}

.boton-movil:hover {
  background-color: var(--color-primero);
  color: #FFF;
  transition: background-color 0.4s ease, color 0.4s ease, margin-left 0.4s ease;
}

/*** SECCIÓN: seccion-servicios/ OFERTA DE SERVICIOS ***/
/* Se trata del gráfico de círculos que informa de los servicios ofrecidos */
.seccion-servicios {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.seccion-servicios h1 {
  margin: 60px 0;
  width: 100%;
  font-size: 70px;
  text-align: center;
}

.seccion-servicios h1 i {
  color: var(--color-segundo);
}

section .servicios-container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 90px;
}

/* Definición de la clase círculo. Hay circulo grande (el central) y circulo pequeño (el de cada servicio) */
section .servicios-container .circulo {
  border-radius: 50%;
  box-shadow: rgba(149, 157, 165, 0.4) 0px 8px 24px;
}

section .servicios-container .circulo.grande {
  border: 10px solid #fff;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 550px;
  width: 550px;
  background: #fff;
  background-image: url(/public/imagenes/foto-mia.jpg);
  background-size: cover;
  background-position: 70% 50%;
  background-repeat: no-repeat;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

/* Cada bloque contiene el texto + el círculo de cada servicio */
section .servicios-container .bloque {
  position: absolute;
  display: flex;
  align-items: center;
  width: 450px;
}

/* Posicionamiento de cada bloque (circulo + texto) */
section .servicios-container .bloque.bloque-uno {
  top: -235px;
  left: -640px;
  justify-content: right;
}

section .servicios-container .bloque.bloque-dos {
  bottom: -235px;
  left: -640px;
  justify-content: right;
}

section .servicios-container .bloque.bloque-tres {
  top: -235px;
  right: -640px;
  justify-content: left;
}

section .servicios-container .bloque.bloque-cuatro {
  bottom: -235px;
  right: -640px;
  justify-content: left;
}

section .servicios-container .bloque .circulo.peque {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 80px;
  background-color: #fff;
  color: #333;
  padding: 0 20px;
  font-size: 2.5rem;
  transition: color 0.25s ease;
  transition: background-color ease 0.3s, color ease 0.3s;
}

/* Margen para cada circulo pequeño, dependiendo de si está a la izquierda (1 y 2) o a la derecha (3 y 4) */
section .servicios-container .bloque.bloque-uno .circulo.peque,
section .servicios-container .bloque.bloque-dos .circulo.peque {
  margin-left: 20px;
}

section .servicios-container .bloque.bloque-tres .circulo.peque,
section .servicios-container .bloque.bloque-cuatro .circulo.peque {
  margin-right: 20px;
}

section .servicios-container .bloque .texto {
  display: flex;
  flex-direction: column;
  text-align: right;
  border-radius: 10px;
  padding: 15px;
  transition: background-color ease 0.3s, color ease 0.3s;
}

section .servicios-container .bloque:hover .texto {
  background-color: rgba(88, 0, 255, 0.9);
  transition: background-color ease 0.3s, color ease 0.3s;
}

section .servicios-container .bloque.bloque-tres .texto,
section .servicios-container .bloque.bloque-cuatro .texto {
  text-align: left;
}

section .servicios-container .bloque h3 {
  color: #333;
  margin: 0;
  display: block;
  text-transform: uppercase;
}

section .servicios-container .bloque p {
  margin: 0;
  display: block;
  color: inherit;
}

section .servicios-container .bloque:hover h3,
section .servicios-container .bloque:hover p {
  color: #fff;
}

section .servicios-container .bloque:hover .circulo.peque {
  background-color: rgba(88, 0, 255, 0.9);
  color: #fff;
  transition: background-color ease 0.3s, color ease 0.3s;
}

/* Efecto de sombra parpadeante; se aplica en la sección de oferta de servicios */
.ripple {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  z-index: -1;
  transform: scale(0);
  animation: myAnimScale 2s ease infinite;
  visibility: hidden;
}

section .servicios-container .bloque:hover .ripple {
  visibility: visible;
}

@keyframes myAnimScale {
  to {
    transform: scale(1.5);
    filter: blur(5px);
    opacity: 0;
  }
}

.show-peque {
  display: none;
}

/* Otras resoluciones */
@media (max-width: 1399px) {
  section .servicios-container .circulo.grande {
    height: 400px;
    width: 400px;
  }

  section .servicios-container .bloque.bloque-uno {
    top: -210px;
    left: -590px;
  }

  section .servicios-container .bloque.bloque-dos {
    bottom: -210px;
    left: -590px;
  }

  section .servicios-container .bloque.bloque-tres {
    top: -210px;
    right: -590px;
  }

  section .servicios-container .bloque.bloque-cuatro {
    bottom: -210px;
    right: -590px;
  }
}

@media (max-width: 1199px) {
  .seccion-servicios h1 {
    font-size: 60px;
    padding: 0 40px;
    line-height: 85px;
  }

  section .servicios-container .circulo.grande {
    height: 340px;
    width: 340px;
  }

  section .servicios-container .bloque {
    width: 380px;
    max-width: 380px;
  }

  section .servicios-container .bloque.bloque-uno {
    top: -200px;
    left: -470px;
  }

  section .servicios-container .bloque.bloque-dos {
    bottom: -200px;
    left: -470px;
  }

  section .servicios-container .bloque.bloque-tres {
    top: -200px;
    right: -470px;
  }

  section .servicios-container .bloque.bloque-cuatro {
    bottom: -200px;
    right: -470px;
  }

  section .servicios-container .bloque.bloque-uno .circulo.peque,
  section .servicios-container .bloque.bloque-dos .circulo.peque {
    margin-left: 10px;
  }

  section .servicios-container .bloque.bloque-tres .circulo.peque,
  section .servicios-container .bloque.bloque-cuatro .circulo.peque {
    margin-right: 10px;
  }

  section .servicios-container .bloque .texto {
    padding: 10px;
  }

  section .servicios-container .bloque h3 {
    font-size: 20px;
  }

  section .servicios-container .bloque p {
    font-size: 14px;
  }
}

@media (max-width: 991px) {
  section .servicios-container {
    display: none;
  }

  .show-peque {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 760px;
    margin-bottom: 60px;
  }

  .show-peque .fila {
    display: flex;
    justify-content: space-around;
  }

  .show-peque .react-reveal {
    width: 48%;
  }

  .seccion-servicios h1 {
    margin: 30px 0;
  }

  section .bloque {
    position: relative;
    display: flex;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    border-radius: 10px;
    margin: 10px;
  }

  section .bloque .circulo.peque {
    border: solid 3px rgba(88, 0, 255, 0.9);
    position: absolute;
    top: 15px;
    right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 80px;
    font-size: 50px;
    background-color: #fff;
    color: #333;
    border-radius: 50%;
  }

  section .bloque .circulo.peque i {
    font-size: 40px;
  }

  section .bloque.bloque-uno .circulo.peque,
  section .bloque.bloque-dos .circulo.peque {
    margin-left: 10px;
  }

  section .bloque.bloque-tres .circulo.peque,
  section .bloque.bloque-cuatro .circulo.peque {
    margin-right: 10px;
  }

  section .bloque .texto {
    padding: 15px;
    letter-spacing: 0;
  }

  section .bloque:hover {
    background-color: rgba(88, 0, 255, 0.9);
    transition: background-color ease 0.3s, color ease 0.3s;
  }

  section .bloque h3 {
    margin-right: 100px;
  }

  section .bloque p {
    margin-bottom: 0;
  }

  section .bloque:hover h3,
  section .bloque:hover p {
    color: #fff;
  }

  section .bloque:hover .circulo.peque {
    color: rgba(88, 0, 255, 0.9);
    transition: background-color ease 0.3s, color ease 0.3s;
    border: none;
  }
}

@media (max-width: 767px) {
  .seccion-servicios h1 {
    font-size: 3rem;
    margin: 15px 0 15px;
    padding: 0 15px;
    line-height: 3.5rem;
  }

  .show-peque .react-reveal {
    width: 100%;
  }

  .show-peque {
    width: 95%;
  }

  .show-peque .fila {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  section .bloque {
    width: 95%;
  }

  section .bloque .circulo.peque {
    top: 50%;
    transform: translateY(-50%);
  }

  section .bloque h3 {
    margin-right: 100px;
  }

  section .bloque p {
    margin-right: 90px;
  }
}

@media (max-width: 400px) {
  .seccion-servicios h1 {
    font-size: 2rem;
    margin: 0;
  }

  section .servicios-container {
    display: none;
  }

  section .bloque .circulo.peque {
    display: none;
  }

  section .bloque h3,
  section .bloque p {
    margin-right: 0;
  }
}

/** Página Servicios **/
.servicios-fondo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  padding: 65px 0;
  box-shadow: 0px 20px 10px rgba(0, 0, 0, 1);
}

.servicios-fondo h1 {
  color: inherit;
  font-size: 100px;
  color: #222;
}

.servicios-fondo p {
  font-size: 30px;
  text-align: center;
  margin: 0 30px 30px;
}

.servicios-contenedor {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
}

.servicios-panel {
  position: relative;
  height: 160px;
  width: 540px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 10px;
  opacity: 0;
  animation: aparecer-progresivo 1s ease-in 0.5s forwards;
}

.servicios-panel.izq {
  padding-right: 180px;
  margin-right: 10px;
}

.servicios-panel.der {
  padding-left: 180px;
  margin-left: 10px;
}

.servicios-panel.retraso-uno {
  animation: aparecer-progresivo 1s ease-in 0.8s forwards;
}

.servicios-panel.retraso-dos {
  animation: aparecer-progresivo 1s ease-in 1.1s forwards;
}

.servicios-panel.retraso-tres {
  animation: aparecer-progresivo 1s ease-in 1.4s forwards;
}

.servicios-panel.retraso-cuatro {
  animation: aparecer-progresivo 1s ease-in 1.8s forwards;
}

@keyframes aparecer-progresivo {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.servicios-panel-texto {
  color: inherit;
  font-size: 30px;
  letter-spacing: -1px;
}

.servicios-panel-texto.izq {
  text-align: right;
}

.servicios-panel-texto .negrita {
  color: #333;
  font-weight: 700;
}

.servicios-panel-icono {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 160px;
  width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 100px;
  border-radius: 50%;
  background-color: #444;
}

.servicios-panel-icono.der {
  right: 0;
}

.servicios-panel-icono.izq {
  left: 0;
}

@media (max-width: 1399px) {
  .servicios-fondo h1 {
    font-size: 100px;
  }

  .servicios-contenedor {
    width: 1100px;
  }

  .servicios-panel {
    width: 490px;
  }

  .servicios-panel-texto {
    font-size: 30px;
  }
}

@media (max-width: 1199px) {
  .servicios-fondo h1 {
    font-size: 75px;
  }

  .servicios-contenedor {
    width: 950px;
  }

  .servicios-panel {
    width: 470px;
  }

  .servicios-panel-texto {
    font-size: 26px;
  }
}

@media (max-width: 991px) {
  .servicios-fondo h1 {
    font-size: 60px;
  }

  .servicios-contenedor {
    width: 720px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .servicios-panel.izq {
    padding-right: 140px;
    margin-right: 10px;
  }

  .servicios-panel.der {
    padding-left: 140px;
    margin-left: 10px;
  }

  .servicios-panel-icono {
    height: 130px;
    width: 130px;
    font-size: 80px;
  }

  .servicios-panel {
    width: 360px;
  }

  .servicios-panel-texto {
    font-size: 22px;
  }
}

@media (max-width: 767px) {
  .servicios-fondo h1 {
    text-align: center;
    font-size: 65px;
  }

  .servicios-contenedor {
    height: 120px;
    width: 550px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .servicios-panel.izq {
    padding-right: 100px;
    margin-right: 10px;
  }

  .servicios-panel.der {
    padding-left: 100px;
    margin-left: 10px;
  }

  .servicios-panel-icono {
    height: 90px;
    width: 90px;
    font-size: 50px;
  }

  .servicios-panel {
    width: 360px;
  }

  .servicios-panel-texto {
    font-size: 18px;
  }
}

/*! He eliminado el gráfico en resoluciones móvil para no complicarme  */
@media (max-width: 575px) {
  .servicios-fondo {
    display: none;
  }

  .servicios-contenedor {
    display: none;
  }
}

.servicios-panel h1 {
  color: #fff;
}

/* Fin página servicios */
.servicios-contenido {
  background-color: #ddd;
}

.servicios-contenido.otro-fondo {
  background: url(/public/imagenes/messytable_marvinmeyer.jpg);
}

.justificar-fila {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.servicios-contenido h1 {
  font-size: 80px;
  color: #222;
}

.servicios-contenido.otro-fondo h1 {
  font-size: 80px;
  color: #fff;
}

.servicios-contenido .columna {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.servicios-contenido h3 {
  color: #222;
  font-size: 3rem;
  letter-spacing: -2px;
}

.servicios-contenido p {
  font-size: 1.2rem;
  letter-spacing: 0px;
  font-weight: 100;
}

.icono-fondo {
  font-size: 175px;
  color: rgba(0, 39, 255, 0.3);
  margin: 15px 0 25px;
}

.my-6 {
  margin: 6rem 0;
}

.icono-servicio-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 330px;
  height: 330px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 30px;
}

.icono-servicio-container i {
  font-size: 215px;
  color: #FFF;
}

.icono-servicio-container-sm-md {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  margin-right: 1rem;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 6px;
}

.icono-servicio-container-sm-md i {
  font-size: 26px;
  color: #FFF;
}

/* En 530px el título rompe */
@media (max-width: 528px) {
  .icono-servicio-container-sm-md {
    transform: translateY(-60%);
  }

  .icono-servicio-container-sm-md i {
    padding: 20px;
  }
}

.mihtres {
  display: flex;
  align-items: center;
}

/* mihtres en resoluciones sm*/
@media (max-width: 767px) {
  .mihtres {
    font-size: 40px;
  }
}

/** Hoja de ruta paso a paso, en el Home **/
/*! Por debajo de 500px de resolución se rompe */
.hoja-de-ruta {
  width: 100%;
}

.hoja-de-ruta h2 {
  font-size: 3rem;
  text-align: center;
  padding: 1rem;
  color: #fff;
  background-color: rgba(88, 0, 255, 0.9);
  border-radius: 6px;
}

.hoja-de-ruta .paso {
  display: flex;
  align-items: center;
}

.hoja-de-ruta .paso h3 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  border: #333 solid 3px;
  margin: 0 20px 0 0;
}

.hoja-de-ruta .paso p {
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 18px;
  color: #fff;
  width: 570px;
  margin: 0;
  padding: 10px;
  border-radius: 8px;
  background-color: rgba(88, 0, 255, 0.9);
}

.hoja-de-ruta i {
  color: #333;
  font-size: 25px;
  transform: translateX(-50%);
  margin: 8px 30px;
}

@media (max-width: 575px) {
  .hoja-de-ruta h2 {
    font-size: 2.3rem;
    padding: 8px;
  }

  .hoja-de-ruta .paso h3 {
    display: none;
  }

  .hoja-de-ruta i {
    text-align: center;
    color: #333;
    font-size: 25px;
    transform: translateX(-50%);
    margin: 8px 50%;
  }
}

/* Sección: Blog ***/
.seccion-blog {
  padding: 20px;
  background-color: #ddd;
}

.seccion-blog h1 {
  width: 100%;
  font-size: 70px;
  text-align: center;
}

.seccion-blog p {
  margin-bottom: 40px;
}

.cartas-container {
  display: flex;
}

.carta-blog {
  cursor: pointer;
  margin: 10px;
}

.carta-blog:hover h3,
.carta-blog:hover p {
  color: #222;
}

.card-subtitle {
  margin-top: -20px;
}

@media (max-width: 767px) {
  .hide-md {
    display: none;
  }
}

/* Página Blog */

.container-thumbnail-blog {
  padding: 0 80px;
}

.blog-titulo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
  /* background-color: rgba(60, 64, 67, 0.08); */
}

.blog-titulo h1 {
  font-size: 5rem;
  margin-bottom: 0;
}

.blog-titulo p {
  font-size: 1.2rem;
}

.thumbnail-blog {
  border-top: #ddd solid 1px;
  display: flex;
  padding: 20px 0;
}

.thumbnail-blog:last-child {
  border-bottom: #ddd solid 1px;
}

.thumbnail-blog .image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.thumbnail-blog img {
  height: 230px;
  border-radius: 10px;
  margin: 0 20px;
}

.thumbnail-blog-texto {
  padding: 15px;
}

.thumbnail-blog-texto a h2:hover {
  color: var(--color-segundo);
  transition: color ease 0.4s;
}

.thumbnail-blog-texto p {
  margin-bottom: 5px;
}

.thumbnail-blog-texto a {
  margin-top: 5px;
}


/* Importa la fuente Bell MT de google fonts */
@import url("https://fonts.googleapis.com/css2?family=Bell+MT&display=swap");

/* Logo */
.container-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-height: 80px;
}

.container-logo h2 {
  font-size: 2.5rem;
  font-weight: 600;
  text-transform: none !important;
  font-family: "Poppins", serif;
  line-height: 1.5rem;
  letter-spacing: 4spx;
}

.container-logo h2 .inicial {
  font-family: inherit;
  background-color: var(--color-segundo);
  color: #fff;
  height: 50px;
  width: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 3px;
}

.container-logo h2 .inicial:first-child {
  width: 55px;
}


.container-logo h3 {
  font-size: 1.3rem;
  font-weight: 500;
}

/* Establishes .container-logo h2 and h3 sizes for all resolutions. Also margin-left 0 */
@media (max-width: 575px) {

  .container-logo {
    margin-right: 0;
  }

  .container-logo h2 {
    font-size: 2rem;
    letter-spacing: 0.12rem;
  }

  .container-logo h3 {
    font-size: 0.75rem;
  }

  .container-logo h2 .inicial {
    height: 40px;
    width: 40px !important;
    text-align: center;
    margin-right: 2px;
  }

}

/* Portfolio */
.portfolio-web-ejemplo {
  cursor: pointer;
  transition: background-color ease 0.4s;
}

/* All text inside .portfolio-web-ejemplo is centered for resolutions sm. Also, a bit of padding is added */
@media (max-width: 575px) {
  .portfolio-web-ejemplo {
    padding-top: 1.8rem;
  }

  .portfolio-web-ejemplo h3 {
    text-align: center;
  }

  .portfolio-web-ejemplo p {
    text-align: center;
  }
}

.portfolio-web-ejemplo:hover {
  color: #555;
  background-color: rgba(0, 0, 0, 0.10);
  transition: background-color ease 0.3s;
}

.portfolio-web-ejemplo p {
  letter-spacing: 0.5px;
}

.titulo-portfolio {
  letter-spacing: 0px;
}

h1.titulo-widgets {
  font-size: 3rem;
  text-align: center;
  margin: 0;

}

/* Otros */

strong {
  font-weight: 600;
}

@media (max-width: 991px) {
  .container-thumbnail-blog {
    padding: 0 15px;
  }

  .thumbnail-blog {
    padding: 8px 0;
  }

  .thumbnail-blog img {
    height: auto;
    width: 200px;
  }

  .thumbnail-blog-texto h2 {
    font-size: 20px;
    margin: 0;
  }

  .thumbnail-blog-texto p {
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .container-thumbnail-blog {
    padding: 0;
  }

  .thumbnail-blog {
    flex-direction: column;
    padding: 8px 0;
  }

  .thumbnail-blog img {
    width: 100%;
  }

  .thumbnail-blog-texto h2 {
    font-size: 20px;
    margin: 10px 0;
  }

  .thumbnail-blog-texto p {
    font-size: 15px;
  }
}

/*! no funciona al poner el href='' en el boton  */
.thumbnail-blog-texto button:hover {
  background-color: var(--color-segundo);
  transition: background-color ease 0.4s;
}

/* .container-feed-blog {} */

.container-feed-blog ul li:hover {
  color: #000;
}

/** Entrada de Blog */
.entrada-blog h2 {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 8px;
  margin-bottom: 1rem;
  font-size: 3rem;
}

@media (max-width: 575px) {
  .entrada-blog h2 {
    font-size: 2rem;
  }
}

.entrada-blog p,
.entrada-blog h3,
.entrada-blog li {
  color: #333;
}

.entrada-blog h3 {
  margin-bottom: 10px;
}

.entrada-blog li {
  margin-bottom: 10px;
}

/* Efectos de animación generales */
.flotar-poco {
  animation: flotarpoco 2s ease-in-out infinite;
}

@keyframes flotarpoco {
  0% {
    transform: translateY(5%);
  }

  50% {
    transform: translateY(-5%);
  }

  100% {
    transform: translateY(5%);
  }
}

.flotar-muy-poco {
  animation: flotarmuypoco 4s ease-in-out infinite;
}

.flotar-muy-poco-dos {
  animation: flotarmuypoco 4s ease-in-out 0.45s infinite;
}

.flotar-muy-poco-tres {
  animation: flotarmuypoco 4s ease-in-out 0.8s infinite;
}

@keyframes flotarmuypoco {
  0% {
    transform: translateY(2%);
  }

  50% {
    transform: translateY(-2%);
  }

  100% {
    transform: translateY(2%);
  }
}

/* Back up */
/* @media (max-width: 1199px) {
	.seccion-servicios h1 {	 }
	.seccion-servicios h1 i {  }
	
	section .servicios-container {  }
	section .servicios-container .circulo {  }
	section .servicios-container .circulo.grande {  }
	
	section .servicios-container .bloque {  }
	section .servicios-container .bloque.bloque-uno {  }
	section .servicios-container .bloque.bloque-dos {  }
	section .servicios-container .bloque.bloque-tres {  }
	section .servicios-container .bloque.bloque-cuatro {  }	

	section .servicios-container .bloque .circulo.peque {  }
	section .servicios-container .bloque.bloque-uno .circulo.peque,
	section .servicios-container .bloque.bloque-dos .circulo.peque {  }
	section .servicios-container .bloque.bloque-tres .circulo.peque,
	section .servicios-container .bloque.bloque-cuatro .circulo.peque {  }	
	
	section .servicios-container .bloque .texto {  }
	section .servicios-container .bloque:hover .texto {  }	
	section .servicios-container .bloque.bloque-tres .texto,
	section .servicios-container .bloque.bloque-cuatro .texto {  }
	section .servicios-container .bloque h3 {  }
	section .servicios-container .bloque p {  }	
	section .servicios-container .bloque:hover h3,
	section .servicios-container .bloque:hover p {  }	
	section .servicios-container .bloque:hover .circulo.peque {  }
} */